<template>
  <div id="survival">
    <div id="filtersearch" v-if="selectedRecord < 0">
      <label for="searchField">&#128270;</label>
      <input
        id="searchField"
        type="text"
        name="searchField"
        v-model="searchTerm"
        value=""
        v-bind:class="{ empty: searchTerm == 0 }"
      />
      <button v-on:click="searchTerm = ''">
        &#9003;
      </button>
    </div>

    <ul
      id="filtertype"
      v-if="selectedRecord < 0"
      class="d-flex align-items-stretch"
    >
      <li v-for="(type, typeName) in types" v-bind:key="type.text">
        <button
          type="button"
          v-on:click="changeType(typeName)"
          v-bind:class="{
            ['bg-' + type.color]: !selectedType || selectedType === typeName,
            inactive: !!selectedType && selectedType !== typeName
          }"
        >
          <img v-bind:alt="type.text" v-bind:src="type.icon" />
          {{ type.text }}
        </button>
      </li>
    </ul>

    <transition-group
      id="categories"
      class="list-group square-list-group"
      name="survival-list"
      tag="ul"
    >
      <li
        v-for="(record, index) in filteredData"
        v-bind:key="record.title"
        class="list-group-item"
        v-bind:class="{
          ['bg-' + types[record.type.name].color]: selectedRecord == index,
          header: selectedRecord == index
        }"
        v-show="selectedRecord < 0 || selectedRecord === index"
      >
        <div class="container">
          <div class="row">
            <a
              v-on:click="recordClicked(index)"
              class="col-auto"
              v-bind:class="'bg-' + types[record.type.name].color"
            >
              <img
                class="typelogo"
                alt="back"
                src="/images/themes/ET/survival/back.svg"
                v-if="selectedRecord == index"
              />
              <img
                class="typelogo"
                v-bind:alt="record.type.text"
                v-bind:src="types[record.type.name].icon"
                v-else
              />
            </a>
            <ButtonDetails
              class="col"
              v-bind:details="record.description"
              v-on:click="recordClicked(index)"
            >
              {{ record.title }}
            </ButtonDetails>
          </div>
        </div>
      </li>
    </transition-group>

    <!-- <div id="filtersearchitems" v-if="selectedRecord >= 0">
         <label for="searchItemsField">&#128270;</label>
         <input
         id="searchItemsField"
         type="text"
         name="searchField"
         v-model="searchItemsTerm"
         value=""
         v-bind:class="{ empty: searchItemsTerm == 0 }"
         />
         <button v-on:click="searchItemsTerm = ''">
         &#9003;
    </button>
  </div>
  -->
    <transition-group
      id="recordcontent"
      class="list-group square-list-group"
      name="survival-list"
      tag="ul"
      v-if="selectedRecord >= 0"
    >
      <li
        v-for="(item, index) in filteredItems"
        v-bind:key="item.title"
        class="list-group-item"
      >
        <div class="card">
          <a
            v-bind:id="'heading' + index"
            class="collapsed card-header"
            data-toggle="collapse"
            v-bind:data-target="'#collapse' + index"
            aria-expanded="false"
            aria-controls="'collapse' + index"
            v-bind:href="'#heading' + index"
          >
            {{ item.title }}
          </a>
          <div
            v-bind:id="'collapse' + index"
            class="collapse"
            aria-labelledby="'heading' + index"
            data-parent="#survival"
          >
            <div class="card-body" v-html="item.content"></div>
          </div>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import ButtonDetails from "@/components/ButtonDetails.vue";

export default {
  name: "SurvivalKit",
  components: {
    ButtonDetails
  },
  mounted: function() {
    this.$store.dispatch("getSurvivalKits").then(() => {
      this.data = this.$store.state.survivalKits;
      this.types = {};
      let id = 0;
      for (let d of this.data) {
        d.id = id++;
        if (!(d.type.name in this.types)) {
          this.types[d.type.name] = d.type;
        }
      }
    });
  },
  data: function() {
    return {
      searchTerm: "",
      searchItemsTerm: "",
      selectedType: "",
      selectedRecord: -1,
      data: null,
      types: null
    };
  },
  methods: {
    changeType: function(t) {
      if (this.selectedType === t) {
        this.selectedType = "";
      } else {
        this.selectedType = t;
      }
    },
    recordClicked: function(index) {
      let id = this.filteredData[index].id;
      this.$router.push("/survivalrecord/" + id.toString());
      /* if (this.selectedRecord == index) {
       *   this.selectedRecord = -1;
       *   this.searchItemsTerm = "";
       * } else {
       *   this.selectedRecord = index;
       * } */
    }
  },
  computed: {
    filteredData: function() {
      var vm = this;
      if (this.data) {
        return this.data.filter(function(item) {
          // search any word in any order, case insensitive
          let searchRegExp = new RegExp(
            "(?=.*" + vm.searchTerm.split(/,|\s/).join(")(?=.*") + ")",
            "gi"
          );
          let tagMatched = false;
          item.tags.forEach(tag => {
            tagMatched = tagMatched || tag.label.match(searchRegExp) !== null;
          });
          return (
            (!vm.selectedType || item.type.name === vm.selectedType) &&
            (item.title.match(searchRegExp) !== null || tagMatched)
          );
        });
      } else {
        return [];
      }
    },
    filteredItems: function() {
      var vm = this;
      if (this.selectedRecord >= 0) {
        return this.filteredData[this.selectedRecord].items.filter(function(
          item
        ) {
          return (
            item.title
              .toLowerCase()
              .indexOf(vm.searchItemsTerm.toLowerCase()) !== -1
          );
        });
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.square-list-group {
  border-radius: 0 !important;
}

#survival {
  #filtersearch,
  #filtersearchitems {
    /* @extend .sticky-top; */
    @extend .d-flex;
    text-align: center;

    label {
      @extend .px-2;
    }
    input {
      @extend .flex-grow-1;
      //      width: 70%;
      & ~ button {
        @extend .btn;
        @extend .btn-light;
        display: inline;
      }
      &.empty {
        //        width: 80%;
        //        margin-left: 1em;
        & ~ button {
          display: none;
        }
      }
    }
  }

  #filtertype {
    text-align: center;
    @extend .p-0;

    li {
      @extend .col;
      @extend .p-1;
      list-style: none;
      display: inline-grid;
      font-size: 1em;
      min-height: 2em;
      margin-left: 1%;
      margin-right: 1%;

      button {
        @extend .btn;
        color: white;

        &:hover {
          color: lightgrey;
        }

        &.inactive {
          background-color: "light-gray";
          color: gray;
        }

        img {
          height: 2em;
        }
      }
    }
  }

  .survival-list-enter,
  .survival-list-leave-to {
    opacity: 0;
  }
  .survival-list-leave-active {
    position: absolute;
  }

  #categories {
    li {
      transition: all 0.5s;
      display: inline-block;
      @extend .p-0;

      a {
        @extend .typelogo;
        @extend .px-0;
        @extend .py-0;
        min-width: 4em;
        text-align: center;
        vertical-align: center;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &:last-of-type {
          /* @extend .px-4; */
          min-width: 4em;
        }

        img {
          /* max-width: 2.5em; */
          max-height: 2.3em;
          /* @extend .w-50; */
          @extend .mx-auto;
        }
      }

      .container {
        @extend .p-0;
        max-width: 100%;
        .row {
          @extend .mx-0;
        }
      }

      &.header {
        @extend .p-0;
        @extend .text-light;
        a {
          @extend .h5;
          @extend .mb-0;
          @extend .text-light;
        }
      }
    }

    .typelogo {
      @extend .rounded;
      @extend .p-2;
      vertical-align: top;
    }
  }

  #recordcontent {
    li {
      @extend .p-1;
    }
  }
}
</style>
