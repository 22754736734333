<template>
  <div>
    <div class="row">
      <details class="col" v-bind:open="open">
        <summary v-on:click.prevent="$emit('click')">
          <slot></slot>
        </summary>
        <p>{{ details }}</p>
      </details>
      <a v-on:click="open = !open" class="col-auto more" v-if="!!details">
        <img
          class="image"
          v-bind:class="open ? 'rotate90' : 'rotate270'"
          v-bind:alt="open ? '-' : '+'"
          src="/images/themes/ET/survival/back.svg"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonDetails",
  props: {
    details: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      open: false
    };
  }
};
</script>

<style scoped lang="scss">
.rotate270 {
  transform: rotate(270deg);
}
.rotate90 {
  transform: rotate(90deg);
}
details {
  display: inline;
  summary {
    display: block;
    @extend .py-3;
  }
  p {
    @extend .px-3;
    @extend .bg-white;
    @extend .py-2;
    @extend .text-dark;
    margin: 0 -4em;
    border-radius: 1em 0 0 0;
  }
}
.more {
  min-width: 3em;
  padding: 0.5em;
  text-align: center;
}
.image {
  height: 2.3em;
}
</style>
